// wait until DOM is ready
document.addEventListener("DOMContentLoaded", function (event) {
  //wait until images, links, fonts, stylesheets, and js is loaded
  window.addEventListener(
    "load",
    function (e) {
      //custom GSAP code goes here

      /**
       *
       * HERO : Heading text animation
       *
       */
      const swiftUpElements = document.querySelectorAll(".swift-up-text");

      function HeadingAnimate() {
        swiftUpElements.forEach((elem) => {
          const words = elem.textContent.split("  ");
          elem.innerHTML = "";

          words.forEach((el, index) => {
            words[index] = `<span><i>${words[index]}</i></span>`;
          });

          elem.innerHTML = words.join(" ");

          const children = document.querySelectorAll("span > i");
          children.forEach((node, index) => {
            node.style.animationDelay = `${index * 0.2}s`;
          });
        });
      }
      if (document.getElementById("single-article-header")) {
        // Heading text animation
        ScrollTrigger.create({
          trigger: "#single-article-header h1",
          onEnter: () => HeadingAnimate(),
        });
      }
      // END Heading text animation

      /**
       *
       * Specific Homepage logo animation
       *
       */

      const homeLogo = document.getElementById("navbar-logo-home");

      //Start menu button animation after different amount of scroll if home or other page
      if (homeLogo) {
        var home = true;
        var mmBgtop = "top -300";
      } else {
        var home = false;
        var mmBgtop = "top -100";
      }

      //Main nav button openers
      ScrollTrigger.create({
        start: mmBgtop,
        end: 99999,
        toggleClass: {
          className: "-scrolled",
          targets: ".mm-bg",
        },
      });
      // END Main nav button openers

      //Homepage animation
      if (home) {
        //Main nav button openers
        ScrollTrigger.create({
          start: "400px",
          end: 99999,
          toggleClass: {
            className: "show",
            targets: "#navbar-home",
          },
        });

        // Place the big logo just on top of the page title (so at the top left point of the box)
        const title = document.getElementById("hero-home");
        const titleCoords = title.getBoundingClientRect();
        const titleY = titleCoords.y; // Value from top of the page
        const position = titleY;

        const smDevice = window.matchMedia("(max-width: 767px)");
        const mdDevice = window.matchMedia(
          "(min-width: 768px) and (max-width: 991px)"
        );
        const lgDevice = window.matchMedia(
          "(min-width: 992px) and (max-width: 1199px)"
        );
        const xlDevice = window.matchMedia(
          "(min-width: 1201px) and (max-width: 1439px)"
        );
        const xxlDevice = window.matchMedia(
          "(min-width: 1440px) and (max-width: 1919px)"
        );
        const xxxlDevice = window.matchMedia(
          "(min-width: 1920px) and (max-width: 2559px)"
        );
        const xxxxlDevice = window.matchMedia("(min-width: 2560px)");

        // Adjust logo spacing and scale ratio depending the breakpoint
        let padding = null;
        let scale = null;
        let imgSelector = ".case-study-preview-large .image.desktop";

        const smPadding = 240;
        const mdPadding = 280;
        const lgPadding = 230;
        const xlPadding = 230;
        const xxlPadding = 280;
        const xxxlPadding = 330;

        const smScale = 3.25;
        const mdScale = 3.5;
        const lgScale = 4;
        const xlScale = 5;
        const xxlScale = 5.7;
        const xxxlScale = 8;

        smDevice.addListener(handleDeviceChange);
        mdDevice.addListener(handleDeviceChange);
        lgDevice.addListener(handleDeviceChange);
        xlDevice.addListener(handleDeviceChange);
        xxlDevice.addListener(handleDeviceChange);
        xxxlDevice.addListener(handleDeviceChange);
        xxxxlDevice.addListener(handleDeviceChange);

        function getGsapValues() {
          if (smDevice.matches) {
            padding = smPadding;
            scale = smScale;
            imgSelector = ".case-study-preview-large .image.mobile";
          }
          if (mdDevice.matches) {
            padding = mdPadding;
            scale = mdScale;
            imgSelector = ".case-study-preview-large .image.medium";
          }
          if (lgDevice.matches) {
            padding = lgPadding;
            scale = lgScale;
            imgSelector = ".case-study-preview-large .image.desktop";
          }
          if (xlDevice.matches) {
            padding = xlPadding;
            scale = xlScale;
            imgSelector = ".case-study-preview-large .image.desktop";
          }
          if (xxlDevice.matches) {
            padding = xxlPadding;
            scale = xxlScale;
            imgSelector = ".case-study-preview-large .image.desktop";
          }
          if (xxxlDevice.matches) {
            padding = xxxlPadding;
            scale = xxxlScale;
            imgSelector = ".case-study-preview-large .image.desktop";
          }
          if (xxxxlDevice.matches) {
            console.log("widest screen");
            padding = xxxlPadding;
            scale = xxxlScale;
            imgSelector = ".case-study-preview-large .image.desktop";
          }
          //return padding;
        }

        function handleDeviceChange(e) {
          if (e.matches) {
            getGsapValues();
          }
        }

        getGsapValues();
        handleDeviceChange(smDevice);
        handleDeviceChange(mdDevice);
        handleDeviceChange(lgDevice);
        handleDeviceChange(xlDevice);
        handleDeviceChange(xxlDevice);
        handleDeviceChange(xxxlDevice);
        handleDeviceChange(xxxxlDevice);

        // const logoHeight = homeLogo.getBoundingClientRect().height;
        // const navWrap = document.getElementById("home-wrapper");

        //BANC HOME LOGO ANIMATION
        function setScrollText() {
          gsap.fromTo(
            homeLogo,
            {
              opacity: 1,
              scale: () => scale,
              transformOrigin: "left bottom",
              force3D: false, // Fix blurry image on safari
              y: () => padding,
              duration: 1,
            },
            {
              scrollTrigger: {
                //trigger: ".hero",
                toggleActions: "play none none none",
                start: "top",
                end: padding - 40 + "px",
                scrub: true,
                pin: "#navbar",
                // aticipatePin: 1,
                pinSpacing: false,
                markers: false,
                invalidateOnRefresh: true,
                //invalidOnRefresh: true,
                // onEnter: (e) => {
                //   e.enable();
                // },
                // onLeave: (e) => {
                //   e.disable();
                // },
                // onEnterBack: (e) => {
                //   e.enable();
                // },
                // onLeaveBack: (e) => {
                //   e.enable();
                // },
              },
              opacity: 1,
              scale: 1,
              y: 0,
            }
          );
        }

        if (window.scrollY < position) {
          setScrollText();
        }

        //CASE STDUY PREVIEWS : ScrollTo Pinned footer sections
        const scenes = gsap.utils.toArray(".case-study-preview-large .content");
        scenes.forEach((scene, i) => {
          ScrollTrigger.create({
            trigger: scene,
            pin: true,
            start: "bottom bottom",
            end: () => `+=${document.querySelector(imgSelector).offsetHeight}`, //End when reaching the bottom of the div
            pinSpacing: false,
          });
        });
        //END Scroll TO Pinned section
      }

      /**
       *
       * Video animation
       *
       *
       * */
      if (document.querySelector(".full-width-video")) {
        // Expand from left
        gsap.to(".video-mask.-left", {
          x: -1000,
          duration: 3,
          scrollTrigger: {
            trigger: ".hero-bottom",
            start: "top center", //Start when the top of the trigger class reaches the center of the viewport
            end: () =>
              `+=${document.querySelector(".full-width-video").offsetHeight}`, //End when reaching the bottom of the div
            scrub: true,
            toggleActions: "play none none none", // onEnter onLeave onEnterBack onLeaveBack
            markers: false,
          },
        });
        // Expand from right
        gsap.to(".video-mask.-right", {
          x: 1000,
          duration: 3,
          scrollTrigger: {
            trigger: ".hero-bottom",
            start: "top center",
            end: () =>
              `+=${document.querySelector(".full-width-video").offsetHeight}`, //End when reaching the bottom of the div
            scrub: true, //Animate when the user scrolls (Note : duration is then disabled),
            toggleActions: "play none none none",
            markers: false,
          },
        });
        // Animate polygon shape to straight (left)
        gsap.to(".video-mask.-left", {
          duration: 1,
          clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 100%)",
          scrollTrigger: {
            trigger: ".hero-bottom",
            start: "top center",
            end: () =>
              `+=${document.querySelector(".full-width-video").offsetHeight}`, //End when reaching the bottom of the div
            scrub: true,
            toggleActions: "play none none none",
            markers: false,
          },
        });
        // Animate polygon shape to straight (right)
        gsap.to(".video-mask.-right", {
          duration: 1,
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          scrollTrigger: {
            trigger: ".hero-bottom",
            start: "top center",
            end: () =>
              `+=${document.querySelector(".full-width-video").offsetHeight}`, //End when reaching the bottom of the div
            scrub: true,
            toggleActions: "play none none none",
            markers: false,
          },
        });

        // Play when entering
        let videoElem = document.querySelector("wistia-video");
        console.log(videoElem);
        ScrollTrigger.create({
          trigger: videoElem,
          start: "top center",
          end: () =>
            `+=${document.querySelector(".full-width-video").offsetHeight}`, //End when reaching the bottom of the div
          markers: false,
          onEnter: () => videoElem.play(),
          onEnterBack: () => videoElem.play(),
          onLeave: () => videoElem.pause(),
          onLeaveBack: () => videoElem.pause(),
        });
        // var videoTimeline = gsap.timeline({
        //   scrollTrigger: {
        //     trigger: ".full-width-video",
        //     scrub: true,
        //     pin: true,
        //     start: "bottom center",
        //     end: "+=100",
        //   },
        // });

        // videoTimeline
        //   .add("start")
        //   .to(
        //     ".video-mask.-left",
        //     {
        //       x: -1500,
        //       duration: 3,
        //     },
        //     "start"
        //   )
        //   .to(
        //     ".video-mask.-right",
        //     {
        //       x: 1500,
        //       duration: 3,
        //     },
        //     "start"
        //   )
        //   .to(
        //     ".video-mask.-left",
        //     {
        //       clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 100%)",
        //       duration: 1,
        //     },
        //     "start"
        //   )
        //   .to(
        //     ".video-mask.-right",
        //     {
        //       clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        //       duration: 1,
        //     },
        //     "start"
        //   );
      }

      /**
       *
       * Animated underlines
       *
       * */

      // HERO BANNER
      gsap.to(".hero .animated-underline", {
        width: "100%",
        duration: 2,
      });

      // DUAL SECTION (enable mutliple)
      let dualSections = gsap.utils.toArray(".dual-section");
      dualSections.forEach(function (dualSection) {
        gsap.to(dualSection.querySelectorAll(".animated-underline"), {
          width: "100%",
          duration: 1,
          scrollTrigger: {
            trigger: dualSection,
            start: "top center",
          },
        });
      });

      // PREVIEW TWO COLUMNS (enable mutliple)
      let previewCols = gsap.utils.toArray(".preview-two-columns");
      previewCols.forEach(function (previewCol) {
        gsap.to(previewCol.querySelectorAll(".animated-underline"), {
          width: "100%",
          duration: 1,
          scrollTrigger: {
            trigger: previewCol,
            start: "top center",
          },
        });
      });

      // CASE STUDIES (enable mutliple)
      let caseStudys = gsap.utils.toArray(".case-study-item");
      caseStudys.forEach(function (caseStudy) {
        gsap.to(caseStudy.querySelectorAll(".animated-underline"), {
          width: "100%",
          duration: 1,
          scrollTrigger: {
            trigger: caseStudy,
            start: "top center",
          },
        });
      });

      // CAREERS (enable mutliple)
      let careers = gsap.utils.toArray(".career-advantages");
      careers.forEach(function (career) {
        gsap.to(career.querySelectorAll(".animated-underline"), {
          width: "100%",
          duration: 1,
          scrollTrigger: {
            trigger: career,
            start: "top center",
          },
        });
      });

      // JOB LIST (enable mutliple)
      let jobLists = gsap.utils.toArray(".job-list");
      jobLists.forEach(function (jobList) {
        gsap.to(jobList.querySelectorAll(".animated-underline"), {
          width: "100%",
          duration: 1,
          scrollTrigger: {
            trigger: jobList,
            start: "top center",
          },
        });
      });

      // JOB LIST (enable mutliple)
      let vacancyPrefooters = gsap.utils.toArray(".vacancy-prefooter");
      vacancyPrefooters.forEach(function (vacancyPrefooter) {
        gsap.to(vacancyPrefooter.querySelectorAll(".animated-underline"), {
          width: "100%",
          duration: 1,
          scrollTrigger: {
            trigger: vacancyPrefooter,
            start: "top center",
          },
        });
      });

      // POSTS HEADER (categories lists) (enable mutliple)
      let postsHeaders = gsap.utils.toArray(".posts-header");
      postsHeaders.forEach(function (postsHeader) {
        gsap.to(postsHeader.querySelectorAll(".animated-underline"), {
          width: "100%",
          duration: 1,
          scrollTrigger: {
            trigger: postsHeader,
            start: "top center",
          },
        });
      });

      // SINGLE POST SIDEBAR (enable mutliple)
      let postsSidebars = gsap.utils.toArray(".sidebar");
      postsSidebars.forEach(function (postsSidebar) {
        gsap.to(postsSidebar.querySelectorAll(".animated-underline"), {
          width: "100%",
          duration: 1,
          scrollTrigger: {
            trigger: postsSidebar,
            start: "top center",
          },
        });
      });
    },
    true
  );
});
