//Setup
var mmBackground = document.createElement("div");
mmBackground.setAttribute("id", "mm_background");
document.body.appendChild(mmBackground);
const label = document.getElementById("mm-label-hover");
const bg = document.getElementById("mm-bg");
const megamenu = document.getElementById("mega-menu");
const $mm1 = document.getElementById("mm1");
const closemenu = document.getElementById("close-mega-menu");
var $mmbg = document.querySelectorAll(".mm-bg");
var $mmlabel = document.querySelectorAll(".mm-label");
var $mmBackground = document.querySelector("#mm_background");
$mmBackground.style.position = "fixed";

var position = {};
var size = {};

//modal action stuffs
var openMMBG = function (event) {
  var $this = bg;
  position = $this.getBoundingClientRect();
  size = {
    width: window.getComputedStyle($this).width,
    height: window.getComputedStyle($this).height,
  };

  $mmBackground.style.position = "fixed";
  $mmBackground.style.top = position.top + "px";
  $mmBackground.style.left = position.left + "px";
  $mmBackground.style.height = size.height;
  $mmBackground.style.width = size.width;
  $mmBackground.style.transform = "skew(-11deg)";
  $mmBackground.style.animation = "skew 0.6s forwards";
  $mmBackground.style.transition = "all .3s";
  //Hide "let's get started" text
  label.style.display = "none";

  setTimeout(function () {
    $mmBackground.innerHTML = $this.innerHTML;
    var classes = $this.classList.value.split(" ");
    for (var i = 0; i < classes.length; i++) {
      $mmBackground.classList.add(classes[i]);
    }
    $mmBackground.classList.add("growing");
    $mmBackground.style.height = "100vh";
    $mmBackground.style.width = "100vw";
    $mmBackground.style.top = "0";
    $mmBackground.style.left = "0";
    $mmBackground.style.margin = "0";
  }, 1);

  setTimeout(function () {
    $mmBackground.classList.remove("growing");
    $mmBackground.classList.add("full-screen");
    megamenu.classList.add("show");
  }, 300);
};

var closeMMBG = function (event) {
  //var $this = event.currentTarget;
  var $this = $mmBackground;
  $this.style.height = size.height;
  $this.style.width = size.width;
  if ($this.classList.contains("-scrolled")) {
    $this.style.top = "-5rem";
  } else {
    $this.style.top = position.top + "px";
  }
  $this.style.left = position.left + 10 + "px";
  //$this.style.margin = "0";
  $mmBackground.style.transform = "skew(-11deg)";
  $mmBackground.style.animation = "skewreverse 0.3s forwards";
  $this.classList.remove("full-screen");
  $this.classList.add("shrinking");
  megamenu.classList.remove("show");
  $mm2.classList.remove("-show");
  $mm3.classList.remove("-show");
  closemenu.style.animation = "none";
  $mm2Opener.classList.remove("active");
  $mm3Opener.classList.remove("active");
  //Show "let's get started" text
  label.style.display = "block";

  setTimeout(function () {
    while ($this.firstChild) $this.removeChild($this.firstChild);
    var classList = $this.classList;
    while (classList.length > 0) {
      classList.remove(classList.item(0));
    }
    $this.style = "";
  }, 300);
};

//Open menu
for (var i = 0; i < $mmbg.length; i++) {
  $mmbg[i].addEventListener("click", openMMBG);
}

for (var i = 0; i < $mmlabel.length; i++) {
  $mmlabel[i].addEventListener("click", openMMBG);
}

// Close menu
closemenu.addEventListener("click", closeMMBG);

// Menu behaviours

const mobile = window.matchMedia("(max-width: 1199px)");
const desktop = window.matchMedia("(min-width: 1200px)");
const mLogoLight = document.getElementById("mobile-logo-light");
const mLogoDark = document.getElementById("mobile-logo-dark");
const $mm2 = document.getElementById("mm2");
const $mm2Opener = document.getElementById("mm2-opener");
const $mm3 = document.getElementById("mm3");
const $mm3Opener = document.getElementById("mm3-opener");
const $backmm1 = document.getElementById("back-mm1");

const openMM2 = function (event) {
  setTimeout(function () {
    $mm2Opener.classList.add("active");
    $mm3Opener.classList.remove("active");
    $mm2.classList.add("-show");
    $mm3.classList.remove("-show");
    if (desktop.matches) {
      closemenu.style.animation = "close 0.6s forwards";
    }
    if (mobile.matches) {
      closemenu.classList.add("-hide");
      $backmm1.classList.remove("-hide");
      mLogoLight.classList.add("-hide");
      mLogoDark.classList.add("-show");
    }
  }, 100);
};

const openMM3 = function (event) {
  setTimeout(function () {
    $mm3Opener.classList.add("active");
    $mm2Opener.classList.remove("active");
    $mm3.classList.add("-show");
    $mm2.classList.remove("-show");
    if (desktop.matches) {
      closemenu.style.animation = "close 0.6s forwards";
    }
    if (mobile.matches) {
      closemenu.classList.add("-hide");
      $backmm1.classList.remove("-hide");
      mLogoLight.classList.add("-hide");
      mLogoDark.classList.add("-show");
    }
  }, 100);
};

const backMenu = function (event) {
  setTimeout(function () {
    $mm2.classList.remove("-show");
    $mm3.classList.remove("-show");
    $mm2Opener.classList.remove("active");
    $mm3Opener.classList.remove("active");
    closemenu.classList.remove("-hide");
    $backmm1.classList.add("-hide");
    mLogoLight.classList.remove("-hide");
    mLogoDark.classList.remove("-show");
  }, 100);
};

//Open submenus
$mm2Opener.addEventListener("click", openMM2);
$mm3Opener.addEventListener("click", openMM3);
//Close submenu
$backmm1.addEventListener("click", backMenu);
//Show label on hover (only if scrolled)
// bg.addEventListener("mouseenter", function (event) {
//   if (this.classList.contains("-scrolled")) {
//     setTimeout(function () {
//       label.classList.add("-show");
//     }, 300);
//   }
// });
// bg.addEventListener("mouseleave", function (event) {
//   if (this.classList.contains("-scrolled")) {
//     setTimeout(function () {
//       label.classList.remove("-show");
//     }, 300);
//   }
// });
// label.addEventListener("mouseenter", function (event) {
//   if (bg.classList.contains("-scrolled")) {
//     setTimeout(function () {
//       this.classList.add("-show");
//     }, 300);
//   }
// });
// label.addEventListener("mouseleave", function (event) {
//   if (bg.classList.contains("-scrolled")) {
//     setTimeout(function () {
//       this.classList.remove("-show");
//     }, 300);
//   }
// });
